<template>
  <v-dialog v-model="dialog" persistent>
    <v-card class="d-flex flex-column" style="height: 100%">
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Response</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0 align-center d-flex flex-column flex-grow overflow-auto"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              v-if="need"
            >
              <label class="title-label">Need Name: </label>
              <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'">
                {{ need.title }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              v-if="agency"
            >
              <label class="title-label">Agency Name: </label>
              <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'">
                {{ agency.name }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label">Your Name: </label>
              <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'">
                {{ profile.first_name }} {{ profile.last_name }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Note*
                <tip-icon>
                  Type any additional notes that you feel would be of use to the
                  agency manager.<br />
                  (Examples include, but are not limited to. preferrences,
                  restrictions, and further availablity details.)
                </tip-icon>
              </label>
              <v-textarea
                v-model="note"
                label="Notes"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                rows="2"
                solo
                flat
                dense
                outlined
                auto-grow
                :error-messages="noteMessage"
                @input="onChange"
                :disabled="status == 'Rejected'"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              v-if="reply"
            >
              <label class="title-label">Reply: </label>
              <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'">
                {{ reply }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
              v-if="status"
            >
              <label class="title-label">Status: </label>
              <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'">
                {{ status }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div style="width: 100%">
          <small>*indicates required field</small>
        </div>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="loading"
          @click="onSubmitNeedResponse"
          v-if="status != 'Rejected'"
        >
          {{ already ? "Edit" : "Submit" }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="cancelling"
          @click="onWithdrawResponse"
          v-if="already && status != 'Rejected'"
        >
          Withdraw
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    TipIcon,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    need: Object,
    shift: Object,
    onCloseDialog: {
      type: Function,
    },
    onResponse: {
      type: Function,
    },
    onCancelled: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackMessage: null,
      error: null,
      note: null,
      agency: null,
      noteMessage: null,
      already: false,
      cancelling: false,
      status: null,
      reply: null,
    };
  },
  methods: {
    ...mapActions({
      submitResponse: "need/respondShift",
      cancelResponse: "need/cancelResponse",
      onUpdateResponseNote: "need/onUpdateResponseNote",
      getAgency: "agency/getAgency",
    }),
    onClose() {
      this.note = null;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    onSubmitNeedResponse() {
      if (this.already) {
        this.onUpdateNote();
      } else {
        this.error = null;
        this.loading = true;
        this.submitResponse({
          need_id: this.need._id,
          shift_id: this.shift._id,
          note: this.note,
        })
          .then((res) => {
            this.loading = false;
            this.note = "";
            this.onResponse(res);
          })
          .catch((error) => {
            this.loading = false;
            this.error = error.response.data.message;
          });
      }
    },
    onWithdrawResponse() {
      this.cancelling = true;
      this.cancelResponse({ _id: this.shift._id })
        .then((res) => {
          this.cancelling = false;
          this.note = "";
          this.onCancelled(res);
        })
        .catch((error) => {
          this.cancelling = false;
          this.snackbar = true;
          this.snackMessage = error.response.data.message;
        });
    },
    onUpdateNote() {
      this.error = null;
      this.loading = true;
      this.onUpdateResponseNote({
        need_id: this.need._id,
        shift_id: this.shift._id,
        note: this.note,
      })
        .then((res) => {
          this.loading = false;
          this.note = "";
          this.onResponse(res);
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    "need.agency": function(newValue) {
      this.getAgency({ _id: newValue })
        .then((res) => {
          this.agency = res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dialog() {
      this.already = false;
      if (this.shift.responses && this.shift.responses.length) {
        this.shift.responses.map((response) => {
          if (response.user_id == this.profile._id) {
            this.already = true;
            this.note = response.note;
            this.reply = response.reply;
            this.status = response.status;
          }
        });
      }
    },
  },
  mounted() {
    if (this.need && this.need.agency) {
      console.log(this.need.response);
      this.getAgency({ _id: this.need.agency })
        .then((res) => {
          this.agency = res;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.title-label {
  width: 200px;
}

.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div :deep(::-webkit-scrollbar) {
  width: 5px;
}

/* Track */
div :deep(::-webkit-scrollbar-track) {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div :deep(::-webkit-scrollbar-thumb) {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div :deep(::-webkit-scrollbar-thumb):hover {
  background: #f0a000;
}

.date-picker {
  border-radius: 4px 0 0 4px;
}
.time-picker {
  border-radius: 0 4px 4px 0;
}
.date-picker :deep(.v-picker__title) {
  max-height: 87px;
}
.time-picker :deep(.v-picker__title) {
  max-height: 87px;
}
</style>
